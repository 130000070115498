import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PesentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2023_10_19-Chao-Visby-webinar.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
		<StyledContainer>
			<WebinarBody
				viewAllowed={props.viewAllowed}
			>
				<div className="flexBox">
					<div className="block block--left">
						<div className="block__content">
							<h2 data-aos="fade-up">Overview</h2>
							<p data-aos="fade-up">
								Watch this webinar for a dive deep into the world of antigen vs. molecular laboratory testing and discover the
								perfect fit for your Urgent Care setting. Navigate the maze of testing pitfalls, debunk common myths, and tailor
								your approach for optimal patient care. Whether you’re battling respiratory bugs or tackling STIs, ensure you’re
								equipped with the best testing strategies and elevate your diagnostic game.</p>
							<h2 data-aos="fade-up">Learning Objectives</h2>
							<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
							<ul data-aos="fade-up">
								<li>Describe the key differences between antigen and molecular laboratory testing, including selecting the “right” test for the Urgent Care setting.</li>
								<li>Identifying and recognizing testing pitfalls</li>
								<li>Choose the appropriate testing methodology for their patient population, disease prevalence and test characteristics</li>
								<li>Describe common misconceptions related to laboratory tests for infectious disease diagnosis</li>
								<li>Evaluate effective testing methods for common infections such as respiratory and sexually transmitted infections</li>
							</ul>
						</div>
					</div>

					<div className="block block--right">
						<div className={(props.pageName === 'display' | props.viewAllowed === false) ? "accessBox show" : "accessBox"}>
							<h3 data-aos="fade-up">Access Includes:</h3>
							<ul data-aos="fade-up">
								<li>Video recording replay</li>
								<li>Presentation Slides (PDF)</li>
							</ul>
						</div>
						<div className={(props.pageName === 'download') ? "downloadBox show showPDF" : "downloadBox"}>
							<h3 data-aos="fade-up">Download</h3>
							<p data-aos="fade-up"><a href={PesentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
						</div>
					</div>
				</div>

				<div className="block block--left">
					<div className="block__content presenter">
						<h2 data-aos="fade-up">About the Presenter</h2>

						<div className="presenterBio" data-aos="fade-up">
							<StaticImage
								src="../../../../assets/images/webinar-replay/chao.jpg"
								width={200}
								alt="Dr. Christopher Chao"
								className="presenter__image"
							/>
							<p data-aos="fade-up">Christopher Chao, MD is the current President of the College of Urgent Care Medicine (CUCM).
								He practices Urgent Care medicine with WakeMed Health and Hospital in Raleigh, North Carolina. After graduating
								from Case Western Reserve University School of Medicine, he completed his residency at Carolinas Medical
								Center (now Atrium Health) with a focus on rural medicine. After residency, he worked in rural family medicine
								clinics for several years before transitioning to full time Urgent Care practice.
							</p>
							<p data-aos="fade-up">Dr. Chao is active with the Urgent Care Association, serving on committees and speaking
								on a variety of clinical topics in Urgent Care medicine. Locally, he serves as a preceptor to APP students and
								medical residents and emphasizes teaching of high-quality, evidence-based cost-effective practices in Urgent Care.
								He is an avid runner and has served as a medical volunteer for several running events.
							</p>
						</div>
					</div>
				</div>
			</WebinarBody>
		</StyledContainer>
	)
}

class FullBody extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			viewAllowed: false
		}
	}

	componentDidMount = props => {
		if((this.props.publicView) || (localStorage.getItem(this.props.localStorageID)) ) {
			this.setState({
				viewAllowed: true
			})
		}
	}

	render = () => (
		<BodyLanding
			pageName={this.props.pageName}
			viewAllowed={this.state.viewAllowed}
			localStorageID={this.props.localStorageID}
		/>
	)
}

export default FullBody
